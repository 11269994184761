import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取所有的工作日志列表
export function GetWorkLogsAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/works/logs/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取工作日志的分页列表
export function GetWorkLogsList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/works/logs/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取所有的工作日志类型
export function GetWorkLogsTypes() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/works/logs/types`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的工作日志数据
export function GetWorkLogsById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/works/logs/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定工作日志的数据
export function PutWorkLogsById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/works/logs/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建工作日志
export function PostWorkLog(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/works/logs`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 删除指定问题的数据
export function DeleteWorkLogById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.delete(`/works/logs/${id}`)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 批量删除
export function MultipleDeleteQuestion(multipleSelectieIds = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(id => {
            multipleRequests.push(apiv1.delete(`/works/logs/${id}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以更新
export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/works/logs/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以禁用/启用
export function GetDeleteCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/works/logs/${id}/can/delete`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
  ____            _                      _ 
 / ___|    __ _  (_) __      __   __ _  (_)
 \___ \   / _` | | | \ \ /\ / /  / _` | | |
  ___) | | (_| | | |  \ V  V /  | (_| | | |
 |____/   \__,_| |_|   \_/\_/    \__,_| |_|
                                           
*/