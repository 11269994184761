<template>
  <section class="base-page">
    <PageHeader
      class="base-page-header"
      :desc="`已加载 ${listData.length} 条，共 ${total} 条`"
    >
      <template #right>
        <el-button
          type="primary"
          class="float-r"
          @click="onAddWorkLog"
          size="small"
          v-if="
            checkPermission([
              'WORK_LOG_ADMIN',
              'WORK_LOG_GROUP_ADMIN',
              'WORK_LOG_USER',
            ])
          "
          >新增工作汇报</el-button
        >
      </template>
    </PageHeader>
    <!-- <From /> -->
    <section class="work-wrap">
      <section class="work-container" id="workLogList">
        <keep-alive
          ><FilterSection @change="onFilterChange" 
        /></keep-alive>
        <transition-group name="list-complete" tag="ul" class="work-log-list">
          <li
            class="list-item"
            v-for="(item, index) in listData"
            :key="'worklog' + index"
          >
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span class="creater-name">
                  {{ item.createdByName }}
                  <span class="log-type"
                    >{{ item.typeName }} -
                    {{ getLogTypeName(item.startDate, item.typeId) }}
                    <span class="log-week-range" v-if="item.typeId === 'WEEKLY'"
                      >[ {{ item.startDate }} - {{ item.endDate }} ]</span
                    >
                  </span>
                </span>
                <span class="log-push-dete" title="发布日期">
                  <i class="el-icon-upload2"></i>
                  {{ item.createdAt | date_time }}</span
                >
              </div>
              <main class="log-content-wrap">
                <div class="flex-box base-shadow">
                  <div class="left">
                    <!-- 当前的日志内容 -->
                    <div class="log-content-block">
                      <p class="log-content-title">
                        {{ getLogTitleType(item.typeId)[0] }}
                      </p>
                      <div class="log-content-text">
                        <el-input
                          style="min-height: 150px"
                          type="textarea"
                          autosize
                          readonly
                          resize="none"
                          v-model="item.content"
                        >
                        </el-input>
                      </div>
                    </div>
                    <!-- 下周/下月的日志内容 -->
                    <el-divider v-if="item.typeId !== 'DAILY'"></el-divider>
                    <div
                      class="log-content-block"
                      v-if="item.typeId !== 'DAILY'"
                    >
                      <p class="log-content-title">
                        {{ getLogTitleType(item.typeId)[1] }}
                      </p>
                      <div class="log-content-text">
                        <el-input
                          style="min-height: 150px"
                          type="textarea"
                          autosize
                          readonly
                          resize="none"
                          v-model="item.nextContent"
                        >
                        </el-input>
                      </div>
                    </div>
                    <!-- 附件板块 -->
                    <el-divider
                      v-if="item.files && item.files.length > 0"
                    ></el-divider>
                    <div
                      class="log-content-block"
                      v-if="item.files && item.files.length > 0"
                    >
                      <p class="log-content-title">附件</p>
                      <div class="log-content-text">
                        <template v-for="item in item.files">
                          <FilesItem
                            :item="item"
                            :showFileName="false"
                            :showFileDesc="false"
                            :showCheckBox="false"
                            :key="item.id"
                            class="mt-r"
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <ClientStatisticStage
                      v-if="
                        checkPermission([
                          'CLIENT_ADMIN',
                          'CLIENT_GROUP_ADMIN',
                          'CLIENT_USER',
                          'CLIENT_OBSERVER',
                        ])
                      "
                      :logItem="item"
                      :ownerId="item.createdById"
                    />
                    <NoData v-else />
                  </div>
                </div>
              </main>
              <footer class="log-item-footer">
                <el-button
                  :loading="item.canUpdateChecking"
                  plain
                  @click="onEdit(item)"
                  size="small"
                  round
                  >{{
                    item.canUpdateChecking ? "权限检验中..." : "编 辑"
                  }}</el-button
                >
                <el-button
                  :loading="item.canDeleteChecking"
                  type="danger"
                  plain
                  @click="onDelete(item)"
                  round
                  size="small"
                  >{{
                    item.canDeleteChecking ? "权限检验中..." : "删 除"
                  }}</el-button
                >
              </footer>
            </el-card>
          </li>
        </transition-group>
        <div class="list-loading" :key="4324234324234">
          <span v-if="!isLast"><i class="el-icon-loading"></i>加载中</span>
          <span v-if="isLast">已全部加载, 共{{ total }}条数据</span>
        </div>
      </section>
    </section>
    <el-dialog
      :title="isEmprty(currentId) ? '新增工作汇报' : '修改工作汇报'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <Form :id="currentId" @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
    <el-backtop target=".work-container">
      <i class="el-icon-upload2"></i>
    </el-backtop>
  </section>
</template>

<script>
import Form from "./components/Form";
import {
  GetWorkLogsList,
  DeleteWorkLogById,
  GetDeleteCheck,
  GetUpdateCheck,
} from "./api";
import { ShowApiError } from "@/request/error";
import { format, getWeek } from "date-fns";
import { isEmprty } from "@/utils/validate";
import FilesItem from "@/views/filesModule/components/FilesItem";
import ClientStatisticStage from "./components/ClientStatisticStage";
import NoData from "@/components/NoData";
import { checkPermission } from "@/utils/auth";
import FilterSection from "./components/FilterSection";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
export default {
  components: {
    PageHeader,
    NoData,
    Form,
    FilesItem,
    FilterSection,
    ClientStatisticStage,
  },
  data() {
    return {
      formDialog: false,
      currentId: null,
      loading: false,
      page: 0,
      size: config.TABLE_SIZE,
      listData: [],
      tableFilters: {},
      typeOptions: [],
      userOptions: [],
      total: 20,
      isLast: false,
      listTarget: null,
      scrollPosition: null,
    };
  },
  created() {
    this.getWorkListData();
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "WorkLogList") {
          if (this.scrollPosition) {
            this.$nextTick(() => {
              let target =
                this.listTarget || document.querySelector("#workLogList");
              target.scrollTop = this.scrollPosition;
            });
          } else {
            this.scrollPosition = null;
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    checkPermission,
    init() {
      let _this = this;
      _this.listTarget = document.querySelector("#workLogList");
      _this.listTarget.addEventListener("scroll", function () {
        _this.scrollPosition = _this.listTarget.scrollTop;
        if (
          _this.listTarget.clientHeight + _this.listTarget.scrollTop >=
          _this.listTarget.scrollHeight
        ) {
          if (!_this.isLast) {
            _this.page = _this.page + 1;
            _this.getWorkListData();
          }
        }
      });
    },
    getWorkListData() {
      this.loading = true;
      GetWorkLogsList({
        page: this.page,
        size: this.size,
        ...this.tableFilters,
      })
        .then((res) => {
          let { content, totalElements, last } = res.data;
          this.listData = [...this.listData, ...content].map((item) => {
            return {
              ...item,
              canDeleteChecking: false,
              canUpdateChecking: false,
              canUpdate: false,
              canDelete: false,
            };
          });
          this.total = totalElements;
          this.isLast = last;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("数据拉取异常", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.listData = [];
      this.getWorkListData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    getLogTypeName(date = "", type = "") {
      if (date == "" || date == null || type == "") {
        return "暂无日期";
      }
      if (type == "DAILY") {
        return format(new Date(date), "yyyy年MM月dd日");
      }
      if (type == "WEEKLY") {
        return `第${getWeek(new Date(date), {
          weekStartsOn: 0,
          firstWeekContainsDate: 4,
        })}周`;
      }
      if (type == "MONTHLY") {
        return format(new Date(date), "yyyy年MM月");
      }
    },
    getLogTitleType(type = "") {
      if (type == "") {
        return "工作计划";
      }
      if (type === "DAILY") {
        return ["今日工作总结", "今日工作总结"];
      }
      if (type === "WEEKLY") {
        return ["本周工作总结", "下周工作计划"];
      }
      if (type === "MONTHLY") {
        return ["本月工作总结", "下月工作计划"];
      }
    },
    onAddWorkLog() {
      this.currentId = "";
      this.formDialog = true;
    },
    onEdit(row) {
      if (row.canUpdate) {
        this.currentId = row.id;
        this.formDialog = true;
        return false;
      }
      row.canUpdateChecking = true;
      GetUpdateCheck(row.id)
        .then((res) => {
          if (res.data) {
            row.canUpdate = res.data;
            row.canUpdateChecking = false;
            this.currentId = row.id;
            this.formDialog = true;
          } else {
            row.canUpdateChecking = false;
            this.currentId = null;
            this.formDialog = false;
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          row.canUpdateChecking = false;
          this.$message.warning("权限校验异常，请稍后再试");
        });
    },
    onDelete(row) {
      if (row.canDelete) {
        this.handleDelete(row);
        return false;
      }
      row.canDeleteChecking = true;
      GetDeleteCheck(row.id)
        .then((res) => {
          if (res.data) {
            row.canDeleteChecking = false;
            row.canDelete = res.data;
            this.handleDelete(row);
          } else {
            row.canDeleteChecking = false;
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          row.canDeleteChecking = false;
          this.$message.warning("权限校验异常，请稍后再试");
        });
    },
    handleDelete(row) {
      this.$msgbox({
        title: "删除提示",
        message: `是否确定要删除该工作日志`,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            DeleteWorkLogById(row.id)
              .then(() => {
                this.listData = this.listData.filter(
                  (listItem) => listItem.id !== row.id
                );
                this.$message.success("操作成功!");
              })
              .catch((err) => {
                ShowApiError("操作请求错误", err);
              })
              .finally(() => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },
    onFormCancel() {
      this.formDialog = false;
      this.currentId = "";
    },
    onFormSuccess(data) {
      let baseCotrol = {
        canDeleteChecking: false,
        canUpdateChecking: false,
        canUpdate: false,
        canDelete: false,
      };
      if (!isEmprty(this.currentId)) {
        let position = 0;
        this.listData.forEach((item, index) => {
          if (item.id === data.id) {
            position = index;
          }
        });
        this.$set(this.listData, position, {
          ...data,
          ...baseCotrol,
        });
      } else {
        if (!this.loading) {
          this.listData.push({
            ...data,
            ...baseCotrol,
          });
        }
      }
      this.formDialog = false;
      this.currentId = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.base-page {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  .base-page-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
.work-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 48px;
  .work-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .work-log-list {
      box-sizing: border-box;
      width: 100%;
      list-style-type: none;
      .list-item {
        box-sizing: border-box;
        width: 100%;
        padding: 15px 0 0 15px;
        transition: all 1s;
        border-radius: 10px;
        overflow: hidden;
        ::v-deep{
          .el-card{
            border-radius: 0;
          }
        }
        .creater-name {
          font-size: 20px;
          color: #000;
          font-weight: bolder;
        }
        .log-type {
          font-size: 16px;
          padding-left: 10px;
          color: #5074dd;
        }
        .log-week-range {
          font-size: 14px;
          color: #989898;
        }
        .log-push-dete {
          float: right;
          font-size: 14px;
          color: #8f8f8f;
          padding-right: 5px;
        }
      }
      .log-content-wrap {
        box-sizing: border-box;
        .flex-box {
          box-sizing: border-box;
          display: flex;
          border: 1px solid #f1f1f1;
          .left {
            box-sizing: border-box;
            flex: 1;
            padding: 15px;
          }
          .right {
            box-sizing: border-box;
            flex: 600px 0 0;
            border-left: 1px solid #f1f1f1;
            // display: flex;
            // align-items: center;
            // justify-content: center;
          }
        }
      }
      .log-content-block {
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        .log-content-title {
          box-sizing: border-box;
          width: 100%;
          padding-left: 20px;
          font-size: 16px;
          color: #000;
          margin-bottom: 10px;
          font-weight: bolder;
          position: relative;
          &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #5074dd;
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
          }
        }
        .log-content-text {
          box-sizing: border-box;
          padding-left: 20px;
          min-height: 50px;
          text-align: left;
          font-size: 15px;
          color: #000;
          line-height: 24px;
          padding-top: 10px;
          padding-right: 10px;
          ::v-deep {
            .el-textarea__inner {
              font-family: "Helvetica Neue", Helvetica, "PingFang SC",
                "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial,
                sans-serif;
              border: none;
              background-color: transparent;
              padding: 0;
              font-size: 16px;
              color: #000;
              line-height: 27px;
            }
          }
        }
      }
      .log-item-footer {
        box-sizing: border-box;
        width: 100%;
        padding-top: 20px;
      }
    }
    .list-loading {
      box-sizing: border-box;
      width: 100%;
      padding: 15px 0;
      font-size: 14px;
      text-align: center;
      color: #fff;
    }
  }
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>