<template>
  <div class="data-wrap" v-loading="loading">
    <h3 class="block-title">统计汇总</h3>
    <p class="error-text" v-if="isError">
      数据获取异常,<span class="text-nav" @click="getTableData">刷新重试</span>
    </p>
    <el-row :gutter="10">
      <el-col :span="12" v-for="item in tableData" :key="item.stageId">
        <div class="state-item">
          <span class="title">{{ item.stageName }}：</span>
          <span class="value text-nav" @click="handleClick(item)">{{
            item.stageId === 1 ? item.total : item.addUp
          }}</span>
        </div>
      </el-col>
      <el-col :span="12"></el-col>
    </el-row>
  </div>
</template>

<script>
import { GetClientStatisticsStages } from "@/views/client/api";
// import { ShowApiError } from "@/request/error";
// import { isEmprty } from "@/utils/validate";
import { compare } from "@/utils/common";
export default {
  props: {
    ownerId: {
      type: Number,
      default: null,
    },
    logItem: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },
  data() {
    return {
      loading: false,
      isError: false,
      tableData: [],
      tableFilter: {
        createdAt: [],
      },
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      GetClientStatisticsStages({
        ownerId: this.ownerId,
        createdAt: this.getCreated()
      })
        .then((res) => {
          this.loading = false;
          this.isError = false;
          let content = res.data;
          content = content.filter(
            (item) => item.stageName !== "" && item.stageSequence !== null
          );
          content = content.sort(compare("stageSequence"));
          this.tableData = content;
        })
        .catch(() => {
          this.loading = false;
          this.isError = true;
        });
    },
    getCreated() {
      if (this.logItem.typeId === "DAILY") {
        return [
          new Date('2021-01-01').toISOString(),
          new Date(this.logItem.startDate).toISOString(),
        ];
      }
      if (this.logItem.typeId === "WEEKLY") {
        return [
          new Date('2021-01-01').toISOString(),
          new Date(this.logItem.endDate).toISOString(),
        ];
      }
      if (this.logItem.typeId === "MONTHLY") {
        return [
         new Date('2021-01-01').toISOString(),
          new Date(this.logItem.endDate).toISOString(),
        ];
      }
    },
    handleClick(item) {
      this.$router.push({
        name: "CientsList",
        params: {
          createdAt: this.getCreated(),
          ownerId: [this.logItem.createdById],
          stageId:item.stageId === 1 ? null : [item.stageId] // 如果是阶段E则默认传null
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.data-wrap {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  overflow: hidden;
  .error-text {
    font-size: 13px;
    color: #989898;
  }
  .block-title {
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    font-weight: bolder;
    position: relative;
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #5074dd;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
  }
  .state-item {
    padding: 8px 0;
    .title {
      font-size: 15px;
      color: #000;
      text-align: left;
      padding-right: 5px;
      font-weight: bolder;
    }
    .value {
      font-size: 14px;
      color: #3a86e9;
      &::after {
        content: " 人";
        font-size: 14px;
        color: #000;
      }
    }
  }
}
</style>