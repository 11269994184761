<template>
  <el-form
    :model="form"
    :rules="rules"
    status-icon
    ref="ruleForm"
    label-width="120px"
    class="work-log-ruleForm"
    :disabled="submitting"
    v-loading="loading"
    element-loading-text="表单初始化中..."
  >
    <el-form-item label="汇报类型：" prop="type">
      <el-radio-group
        v-model="form.type"
        @change="onTypeChange"
        :disabled="!isEmprty(id)"
      >
        <el-radio-button
          v-for="item in typeOptions"
          :label="item.id"
          :key="item.name"
          >{{ item.name }}</el-radio-button
        >
      </el-radio-group>
    </el-form-item>
    <el-form-item label="日期：" prop="startDate">
      <el-date-picker
        v-if="currentType === 'DAILY'"
        v-model="dayValue"
        value-format="yyyy-MM-dd"
        type="date"
        style="width: 100%"
        @change="onDayChange"
        placeholder="选择日期"
      >
      </el-date-picker>
      <el-date-picker
        v-if="currentType === 'WEEKLY'"
        v-model="weekValue"
        type="week"
        format="yyyy 第 WW 周"
        style="width: 100%"
        @change="onWeekChange"
        placeholder="选择周"
      >
      </el-date-picker>
      <el-date-picker
        v-if="currentType === 'MONTHLY'"
        v-model="monthValue"
        type="month"
        format="yyyy 年 MM 月"
        style="width: 100%"
        @change="onMonthChange"
        placeholder="选择月"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item :label="contentLabel" prop="content">
      <el-input
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 99 }"
        placeholder="请输入内容"
        v-model="form.content"
      >
      </el-input>
    </el-form-item>
    <el-form-item :label="nextContentLabel" v-if="form.type !== 'DAILY'">
      <el-input
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 99 }"
        placeholder="请输入"
        v-model="form.nextContent"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="上传文件：" prop="fileIds">
      <el-button type="primary" size="small" @click="showFileDialog = true">{{
        fileList.length > 0 ? `已选${fileList.length}个文件` : "选择文件"
      }}</el-button>
      <div class="file-content-list" v-if="fileList.length > 0">
        <template v-for="(item, index) in fileList">
          <FilesItem
            showDelete
            :showFileName="false"
            :showFileDesc="false"
            :showCheckBox="false"
            :item="item"
            @onDelete="onFileDelete(index)"
            :key="item.id"
            class="mt-r"
          />
        </template>
      </div>
    </el-form-item>

    <el-form-item style="text-align: right">
      <el-button @click="onCancel" :disabled="submitting" :loading="loading"
        >关 闭</el-button
      >
      <el-button
        v-if="isEmprty(id)"
        type="primary"
        :loading="submitting"
        @click="submitForm('ruleForm')"
        >立即创建</el-button
      >
      <el-button
        type="warning"
        :loading="submitting"
        @click="editForm('ruleForm')"
        v-if="!isEmprty(id)"
        >立即修改</el-button
      >
    </el-form-item>
    <el-dialog
      title="选择文件"
      :visible.sync="showFileDialog"
      append-to-body
      :close-on-click-modal="false"
      width="80%"
    >
      <FilesView
        ref="FilesView"
        :fileItems="fileList"
        @confirm="onFileConfirm"
        @cancel="onFileCancel"
      />
    </el-dialog>
  </el-form>
</template>

<script>
import {
  GetWorkLogsTypes,
  GetWorkLogsById,
  PutWorkLogsById,
  PostWorkLog,
} from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import FilesView from "@/views/filesModule/FilesView";
import FilesItem from "@/views/filesModule/components/FilesItem";
import {
  lastDayOfWeek,
  startOfWeek,
  startOfMonth,
  lastDayOfMonth,
  addDays,
  format,
} from "date-fns";
export default {
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
  },
  components: {
    FilesView,
    FilesItem,
  },
  data() {
    return {
      loading: false,
      submitting: false,
      showFileDialog: false,
      fileList: [],
      currentType: "DAILY",
      contentLabel: "今日工作计划",
      nextContentLabel: "今周工作计划",
      form: {
        content: "",
        nextContent: "",
        endDate: "",
        startDate: new Date(),
        fileIds: [],
        type: "",
      },
      dayValue: "",
      weekValue: "",
      monthValue: "",
      typeOptions: [],
      rules: {
        content: [
          { required: true, validator: this.checkContent, trigger: "blur" },
        ],
        nextContent: [
          {
            required: true,
            validator: this.checkNextContent,
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择汇报类型",
            trigger: ["blur", "change"],
          },
        ],
        startDate: [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    id: {
      handler() {
        this.getWorkTypeOptions();
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        } else {
          this.initForm();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    checkContent(rule, value, callback) {
      // console.log('rule', rule)
      if (!value) {
        if (this.form.type === "DAILY") {
          return callback(new Error("请输入今日工作总结"));
        }
        if (this.form.type === "WEEKLY") {
          return callback(new Error("请输入本周工作总结"));
        }
        if (this.form.type === "MONTHLY") {
          return callback(new Error("请输入本月工作总结"));
        }
      } else {
        callback();
      }
    },
    checkNextContent(rule, value, callback) {
      // console.log('rule', rule)
      if (!value) {
        if (this.form.type === "WEEKLY") {
          return callback(new Error("请输入下周工作计划"));
        }
        if (this.form.type === "MONTHLY") {
          return callback(new Error("请输入下月工作计划"));
        }
      } else {
        callback();
      }
    },
    onTypeChange(val) {
      this.currentType = val;
      if (this.form.type === "DAILY") {
        this.contentLabel = "今日工作计划";
        this.nextContentLabel = "今日工作计划";
        this.setFormDate(new Date(), "day");
        this.dayValue = new Date();
      }
      if (this.form.type === "WEEKLY") {
        this.contentLabel = "本周工作总结";
        this.nextContentLabel = "下周工作计划";
        this.setFormDate(new Date(), "week");
        this.weekValue = new Date();
      }
      if (this.form.type === "MONTHLY") {
        this.contentLabel = "本月工作总结";
        this.nextContentLabel = "下月工作计划";
        this.setFormDate(new Date(), "month");
        this.monthValue = new Date();
      }
      this.resetForm();
    },
    onDayChange(val) {
      this.setFormDate(val, "day");
    },
    onWeekChange(val) {
      this.setFormDate(val, "week");
    },
    onMonthChange(val) {
      this.setFormDate(val, "month");
    },
    setFormDate(date = "", type = "") {
      if (isEmprty(date) || isEmprty(type)) {
        this.form.startDate = "";
        this.form.endDate = "";
        return false;
      }
      let result = [];
      this.form.startDate = "";
      this.form.endDate = "";
      if (type == "day") {
        this.monthValue = "";
        this.weekValue = "";
        result = [
          format(new Date(date), "yyyy-MM-dd"),
          format(new Date(date), "yyyy-MM-dd"),
        ];
      }
      if (type == "week") {
        this.monthValue = "";
        this.dayValue = "";
        result = this.getDayByWeek(date);
      }
      if (type == "month") {
        this.weekValue = "";
        this.dayValue = "";
        result = this.getDayByMonth(date);
      }
      if (result.length > 0) {
        this.form.startDate = format(new Date(result[0]), "yyyy-MM-dd");
        this.form.endDate = format(new Date(result[1]), "yyyy-MM-dd");
      } else {
        this.form.startDate = "";
        this.form.endDate = "";
      }
    },
    getDayByWeek(date = "") {
      let result = [];
      if (date !== "") {
        result = [startOfWeek(new Date(date)), lastDayOfWeek(new Date(date))];
      }
      return result;
    },
    getDayByMonth(date = "") {
      let result = [];
      if (date !== "") {
        result = [startOfMonth(new Date(date)), lastDayOfMonth(new Date(date))];
      }
      return result;
    },
    isEmprty,
    getWorkTypeOptions() {
      this.loading = true;
      GetWorkLogsTypes()
        .then((res) => {
          this.typeOptions = res.data;
          if (
            this.typeOptions.length > 0 &&
            isEmprty(this.id) &&
            this.form.type == ""
          ) {
            this.currentType = this.typeOptions[0].id;
            this.form.type = this.typeOptions[0].id;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取类型失败", err);
        });
    },
    formParams() {
      return {
        content: this.form.content,
        nextContent: this.form.nextContent,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        typeId: this.form.type,
        fileIds: this.fileList.map((item) => item.id),
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostWorkLog(this.formParams())
            .then((res) => {
              this.submitting = false;
              this.initForm();
              this.onEmitSuccess(res.data);
              this.$refs[formName].clearValidate();
              this.$message.success("新建成功");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("新增失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },

    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutWorkLogsById(this.id, this.formParams())
            .then((res) => {
              this.submitting = false;
              this.initForm();
              this.onEmitSuccess(res.data);
              this.$refs[formName].clearValidate();
              this.$message.success("修改成功");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData(id = "") {
      this.loading = true;
      GetWorkLogsById(id)
        .then((res) => {
          let { files, typeId } = res.data;
          this.fileList = files;
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.form[key] = res.data[key];
            }
          }
          this.currentType = typeId;
          this.form.type = typeId;
          this.form.fileIds = files.map((item) => item.id);
          this.dayValue =
            this.form.type == "DAILY" ? new Date(this.form.startDate) : "";
          this.monthValue =
            this.form.type == "MONTHLY" ? new Date(this.form.startDate) : "";
          // 此处使用 addDays的目的是用来修复element周数选择器的显示的bug
          // element周数选择器的选择面板显示一周的日期范围是周日到周六，但是选中获取回来的值是周一那天的值，选中的显示结果也是从周一开始算的。
          // 所以显示的值比实际的值大一天，所以需要给拿回来的值加一天才能让element的周数选择器显示正确
          this.weekValue =
            this.form.type == "WEEKLY"
              ? addDays(new Date(this.form.startDate), 1)
              : "";
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
    onCancel() {
      this.initForm();
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
      });
      this.$emit("cancel");
    },
    onEmitSuccess(data = {}) {
      this.$emit("success", data);
    },
    resetForm() {
      this.$nextTick(() => {
        if (this.$refs["ruleForm"]) {
          this.$refs["ruleForm"].clearValidate();
        }
      });
    },

    initForm() {
      this.weekValue = "";
      this.monthValue = "";
      this.dayValue = "";
      this.form = {
        content: "",
        nextContent: "",
        endDate: "",
        startDate: new Date(),
        fileIds: [],
        type: this.currentType,
      };
      this.fileList = [];
      this.onTypeChange(this.currentType);
    },
    onFileConfirm({ items }) {
      this.fileList = items;
      this.showFileDialog = false;
    },
    onFileCancel() {
      this.showFileDialog = false;
    },
    onFileDelete(index) {
      this.fileList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.work-log-ruleForm {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.file-content-list {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>