<template>
  <section class="filter-wrap base-shadow">
    <el-form ref="form" :model="tableFilters" label-width="auto">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="日志开始日期">
            <el-date-picker
            style="width: 100%"
              v-model="tableFilters.startDate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy/MM/dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="日志结束日期">
            <el-date-picker
            style="width: 100%"
              v-model="tableFilters.endDate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy/MM/dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="日志类型">
            <BasicSelect
              v-model="tableFilters.typeId"
              :allOptions="typeOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8" v-if="showMoreFilter">
          <el-form-item label="创建日期">
            <el-date-picker
              v-model="tableFilters.createdAt"
              type="daterange"
              style="width: 100%"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :sm="24" :md="12" :lg="8" v-if="showMoreFilter">
          <el-form-item label="最后修改日期">
            <el-date-picker
              v-model="tableFilters.lastModifiedAt"
              type="daterange"
              style="width: 100%"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8" v-if="showMoreFilter">
          <el-form-item label="创建人">
            <BasicSelect
              v-model="tableFilters.createdById"
              :allOptions="userOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onResetFilter">重置</el-button>
            <el-button type="text" @click="showMoreFilter = !showMoreFilter">
              更多
              <i
                :class="
                  showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import BasicSelect from "@/components/BasicSelect";
import { onClearFilter } from "@/utils/common";
import { GetUsersAll } from "@/views/users/api";
import { GetWorkLogsTypes } from "../api";
export default {
  components: {
    BasicSelect,
  },
  data() {
    return {
      showMoreFilter: false,
      userOptions: [],
      typeOptions: [],
      tableFilters: {
        createdAt: null,
        lastModifiedAt: null,
        createdById: null,
        startDate: "",
        endDate: "",
        typeId: null,
      },
    };
  },
  created() {
    this.initSelection();
  },
  methods: {
    onSearch() {
      this.$emit("change", this.tableFilters);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.$emit("change", this.tableFilters);
    },
    initSelection() {
      GetUsersAll().then((res) => {
        this.userOptions = res.data;
      });
      GetWorkLogsTypes().then((res) => {
        this.typeOptions = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin-top: 10px;
  padding: 10px;
  padding-bottom: 0;
  background-color: #fff;
}
</style>